@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-height: 100vh) {
  footer.hidden {
    display: absolute;
  }
}

html {
  font-family: "Montserrat", sans-serif;
}

.pagination-enter {
  position: absolute;
  opacity: 0;
}
.pagination-enter-active {
  position: absolute;
  opacity: 1;
  transition: opacity 600ms;
}
.pagination-exit {
  position: absolute;
  opacity: 1;
}
.pagination-exit-active {
  position: absolute;
  opacity: 0;
  transition: opacity 600ms;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a59719;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a59719;
}

::-webkit-scrollbar {
  display: none;
}
